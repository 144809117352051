import apartment from "../assets/img/apartment.jpg";
import furniture from "../assets/img/furniture.jpg";


export const rowdata = [
  {
    id: 1,
    img: furniture,
    title: "Home Furniture: Elegant & Affordable",
    desc: "Transform your living spaces with our stylist and affordable home furniture collection. From delectable dining tables to plush pillows, our mixes of classic and contemporary furniture and accessories can make your house a home.",
  },
  {
    id: 2,
    img: apartment,
    title: "Apartment Bookings: Your Home Away From Home",
    desc: "Find the perfect for rent with our seamless	booking process. Our apartments are fully furnished and equipped with all the amenities you need to feel right at home. Simply choose your preferred apartment, book, and move in.",
  },
];