import React from "react";
import Button from "../common/Button";

const items = [
  {
    id: 1,
    img: "https://assets.maalexi.com/images/home/blockchain.svg",
    title: "Blockchain",
    description:
      "Securing And Validating Documents On Blockchain For Greater Trust And Transparency",
    label: "Shop Now",
  },
  {
    id: 2,
    img: "https://assets.maalexi.com/images/home/ai.svg",
    title: "AI Risk Model",
    description:
      "Enhancing And Accelerating Decision Making With AI Risk Assessment And Scoring",
    label: "Book Now",
  },
];

function Shop() {
  return (
    <div className="md:py-[80px] py-[67px] bg-white">
      <div className="mx-auto md:w-full w-11/12 container">
        <div className="w-full">
          <div className="pt-[48px] w-full md:grid md:grid-cols-2 gap-[24px]">
            {items.map((item) => (
              <Item
                key={item.id}
                image={item.img}
                title={item.title}
                description={item.description}
                label={item.label}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Shop;

const Item = ({ title, description, image, label }) => {
  const overlayStyle = {
    background: " rgba(0, 0, 0, 0.2)",
  };

  return (
    <div className="relative w-full md:h-[600px] md:h-[600px] h-[314px]  overflow-hidden md:mb-0 mb-[24px] rounded-3xl">
      <div className="group">
        <img
          src={image}
          alt="Background"
          className="object-cover w-full md:h-[710px] md:h-[710px] h-[314px] transition-transform duration-300 transform-gpu group-hover:scale-110 pointer-events-none"
        />
        <div className={`absolute inset-0`} style={overlayStyle}></div>
        <div className="absolute md:bottom-[55px] md:bottom-[30px] bottom-[34px] md:left-[36px] md:left-[15px] left-[20px] md:p-4">
          <h1 className="text-white md:text-[32px] text-[21px] font-medium leading-[43px] font-roman">
            {title}
          </h1>
          <p className="text-white md:mt-2 mt-[8px] md:leading-[27px] md:w-[393px] md:w-[286px] w-[276px] font-sans md:text-base text-sm leading-[22px]">
            {description}
          </p>
          <div className="pt-3 w-[200px]">
            <Button label={label} variant={2} />
          </div>
        </div>
      </div>
    </div>
  );
};
