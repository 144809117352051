import React, { useState, useEffect } from "react";
import Button from "../common/Button";
import one from "../../assets/img/1k.JPG";
import two from "../../assets/img/2k.JPG";
import three from "../../assets/img/3k.JPG";
import four from "../../assets/img/4k.JPG";
import five from "../../assets/img/5k.JPG";
import six from "../../assets/img/6k.JPG";

const images = [
  one,
  two,
  three,
  four,
  five,
  six
];
const Design = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const autoPlayInterval = setInterval(nextSlide, 3000); // Auto-play every 3 seconds

    return () => {
      clearInterval(autoPlayInterval); // Clear the interval when the component unmounts
    };
  }, []);

  const items = [
    "Your Name",
    "Your Contact Number",
    "A few images of your dream item",
    "Your product dimensions or size",
    "Details about materials",
    "Delivery address",
  ];
  return (
    <>
     
      <section className="pb-[50px] bg-white w-full h-full">
        <div className="md:w-full w-11/12 mx-auto container ">
          <div
            className={`w-full flex  items-center justify-between gap-8 flex-col md:flex-row`}
          >
            <div className="w-full">
              <h3 className="md:pb-1 py-3 text-start font-roman font-bold md:text-[50px] text-[30px] text-[#5A2C1A]">
                Design your own Furniture
              </h3>
              <p className=" text-start font-sans font-medium md:text-xl text-sm text-black/70 ">
                Let's sit down and talk about your vision
              </p>
              <p className=" text-start font-sans font-medium md:text-base text-sm text-black/70 underline">
                Please send us the following:
              </p>
              <div className="mt-6">
                {items.map((item, idx) => {
                  return (
                    <div key={idx} className="flex items-center gap-4 pb-3">
                      <div className="h-[6px] w-[8px] bg-[#62554a]" />
                      <p className=" text-start font-sans font-medium md:text-base text-sm text-black/70">
                        {item}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="w-[200px]">
                <Button
                  label="Email Us"
                  variant={1}
                  to="mailto:info@moderndayhomes.co.za"
                />
              </div>
            </div>
            <div
              className={` w-full overflow-hidden md:h-[594px] h-[365px]"
              `}
            >
              <div
                id="default-carousel"
                class="relative w-full"
                data-carousel="slide"
              >
                <div class="relative h-56 overflow-hidden rounded-3xl md:h-[594px]">
                  <div class="duration-700 ease-in-out" data-carousel-item>
                    <img
                      src={images[currentIndex]}
                      class="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
                      alt="..."
                    />
                  </div>
                </div>

                <button
                  onClick={prevSlide}
                  type="button"
                  class="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  data-carousel-prev
                >
                  <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg
                      class="w-4 h-4 text-white dark:text-gray-800"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 1 1 5l4 4"
                      />
                    </svg>
                    <span class="sr-only">Previous</span>
                  </span>
                </button>
                <button
                  onClick={nextSlide}
                  type="button"
                  class="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                  data-carousel-next
                >
                  <span class="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                    <svg
                      class="w-4 h-4 text-white dark:text-gray-800"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    <span class="sr-only">Next</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Design;
