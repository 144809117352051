import React from "react";
import DiscoverCard from "./DiscoverCard";

const Discover = () => {

  const data = [
    {
      id: 1,
      title: "Transform Your Space",
      desc: " Elevate Your Lifestyle with Our Stylish Furniture and Exclusive Apartment Rentals!",
    },
    {
      id: 2,
      title: "Discover the Perfect Harmony",
      desc: " From Furniture Elegance to Dream Rentals - Your Home, Your Sanctuary!",
    },
    {
      id: 3,
      title: "Unlock Comfort and Style",
      desc: "Explore Exclusive Furniture Deals and Premium Apartment Rentals Today!",
    },
  ];
  return (
    <section className="md:py-[120px] py-[40px] bg-white w-full">
      <div className="container md:w-full w-11/12 mx-auto">
        <div className="w-full flex flex-col md:flex-row justify-between md:gap-[70px] gap-3">
          <h3 className="md:w-[45%] w-full md:py-5 py-2 text-start font-roman font-bold md:text-[42px] text-[22px] text-[#5A2C1A]">
            Discover the Easy Way to Furnish and Book Your Dream Home
          </h3>
          <p className="md:w-[55%] w-full md:py-5 text-start font-sans font-medium md:text-lg text-sm text-black/80">
            At Modern day homes, we make it simple for you to find the perfect
            furniture and book apartments. Browse our wide selection of
            high-quality furniture and choose the pieces that suit your style
            and needs. Then, easily book your preferred apartment and enjoy a
            hassle-free move-in process.
          </p>
        </div>
        <div className="pt-[50px] w-full md:grid md:grid-cols-3 flex flex-col md:flex-row gap-[25px] ">
          {data.map((item) => (
            <DiscoverCard key={item.id} data={item} />
          ))}
         
        </div>
      </div>
    </section>
  );
};

export default Discover;
