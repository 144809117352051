import React, { FC, ButtonHTMLAttributes } from "react";
import { Link } from "react-router-dom";

const Button = ({
  type = "button",
  label,
  variant = 1,
  to,
  className = "full",
  loading,
  ...props
}) => {
  if (variant === 2) {
    return (
      <Link
        to={to}
        className={` w-full my-2 flex items-center justify-center  font-bold  bg-white px-[40px] py-[12px] cursor-pointer font-sans rounded-xl`}
        {...props}
      >
        <span className="w-full">{label}</span>
      </Link>
    );
  }
  return (
    <Link
      to={to}
      className={` w-full my-2 flex items-center justify-center p-[1.5px] font-bold  bg-black/80 text-white font-sans px-[48px] py-[12px] cursor-pointer rounded-xl`}
      {...props}
    >
      {label}
    </Link>
  );
};

export default Button;
