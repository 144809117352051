import React from "react";
import logo from "../../assets/img/logo.png";
import { MdChair, MdTableRestaurant } from "react-icons/md";
import { SiCodesignal } from "react-icons/si";
import { RiArtboard2Fill } from "react-icons/ri";

const items = [
  {
    id: 1,
    icon: MdChair,
    title: "Chairs",
    desc: "Timeless, sophisticated & sculpted",
  },
  {
    id: 2,
    icon: MdTableRestaurant,
    title: "Tables",
    desc: "Graceful, essential & versatile.",
  },
  {
    id: 3,
    icon: SiCodesignal,
    title: "Decor",
    desc: "Complimentary, pierced & elegant.",
  },
  {
    id: 4,
    icon: RiArtboard2Fill,
    title: "Moodboards",
    desc: "Innovative, creative & futuristic.",
  },
];

const Luxury = () => {
  const Item = ({ item }) => {
    const Icon = item?.icon;
    return (
      <div className="md:p-[40px] p-[20px] hover:outline hover:outline-[#5A2C1A] my-2 cursor-pointer last:outline">
        <Icon className="md:text-[50px] text-[35px] text-[#5A2C1A]" />
        <h3 className="font-roman md:text-[32px] text-xl font-bold w-full leading-[120%] text-[#5A2C1A] py-4 ">
          {item?.title}
        </h3>
        <p className=" md:pt-3 text-start font-sans font-medium md:text-base text-sm text-[#62554a]/90 w-[80%]">
          {item?.desc}
        </p>
      </div>
    );
  };
  return (
    <>
    
      <section className="md:py-[150px] py-[70px] bg-white w-full ">
        <div className="md:w-full w-11/12 mx-auto container">
          <div className="w-full flex items-center justify-center flex-col">
            <h3 className="font-roman md:text-[52px] text-[32px] font-bold text-center md:w-[50%] w-[95%] leading-[120%] text-[#5A2C1A]">
              Luxurious Handcrafted Furniture & Decor
            </h3>
            <p className="md:w-[55%] w-[75%] md:py-5 py-4 text-center font-sans font-medium md:text-lg text-base text-black/70 italic">
              made in South Africa
            </p>
          </div>
          <div className="w-full md:grid md:grid-cols-4 flex flex-col md:flex-row">
            {items.map((item) => (
              <Item key={item.id} item={item} />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Luxury;
