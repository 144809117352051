import React from "react";
import {
  BsFillPlayFill,
} from "react-icons/bs";

const Hero = () => {
  return (
    <section className="w-full pt-[100px] h-full bg-[#E3BC9A] bg-hero-bg bg-cover">
      <div className="container md:w-full w-11/12 pt-[48px] mx-auto">
        <div className="w-full flex items-center justify-center flex-col">
          <h3 className="font-roman md:text-[52px] text-[30px] font-bold text-center md:w-[70%] w-[95%] leading-[120%] text-black/80">
            Discover Modern Day Homes' Exceptional Furniture and Apartments
          </h3>
          <p className="md:w-[55%] w-[75%] md:py-5 py-4 text-center font-sans font-medium md:text-lg text-base text-black/70">
            Experience the perfect blend of style and comfort with our wide
            range of furniture options. Book your dream apartment with us
          </p>
          <div className="md:h-[500px] h-[230px] md:w-[80%] w-[93%] mt-3 rounded-tl-[12px] rounded-tr-[12px] overflow-hidden relative bg-hero bg-cover bg-no-repeat">
            <div className="relative w-full h-full  overflow-hidden ">
              <div className="absolute inset-0 "></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="md:w-20 md:h-20 h-14 w-14 bg-[#5A2C1A] rounded-full flex items-center justify-center play-button cursor-pointer">
                  <BsFillPlayFill className="md:text-[50px] text-[30px] text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
