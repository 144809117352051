import React from "react";
import { BiSolidCube } from "react-icons/bi";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";

const DiscoverCard = ({ data }) => {
  return (
    <div className="w-full md:my-0 my-5">
      <BiSolidCube className="md:text-[40px] text-[30px] text-[#5A2C1A]" />
      <h3 className="md:py-5 py-3 text-start font-roman  font-bold md:text-2xl text-xl text-[#5A2C1A] w-[80%]">
        {data?.title}
      </h3>
      <p className="text-start font-sans font-medium md:text-base text-sm text-black/70 md:w-[88%] w-full">
        {data?.desc}
      </p>
      <Link to="" className="flex items-center md:mt-8 mt-5">
        <p className="text-start font-sans font-medium text-sm text-[#5A2C1A] md:pr-4 pr-2">
          Explore
        </p>
        <FaAngleRight className="text-base text-[#5A2C1A] " />
      </Link>
    </div>
  );
};

export default DiscoverCard;
