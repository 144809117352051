import React from "react";
import Button from "../common/Button";

const Cta = () => {
  return (
    <section className="py-[50px] md:mt-[89px] mt-4 w-full bg-[#E3BC9A] ">
      <div className="md:w-full w-11/12 mx-auto container">
        <div className="md:p-[50px] p-[25px] w-full border-4 border-[#62554a] flex flex-col md:flex-row items-center justify-between rounded-xl">
          <div className="md:w-full w-full">
            <h3 className="font-roman md:text-[32px] text-[22px] font-bold leading-[120%] text-black/80">
              Discover the Perfect Furniture Pieces
            </h3>
            <p className="md:pt-5 pt-3 md:pb-0 pb-2 font-sans font-medium md:text-md text-sm text-black/70">
              Transform Your Space with Modern Furniture and Decor
            </p>
          </div>
          <div className="flex items-center gap-6">
            <Button label="Shop Furnitures" variant={1} />
            <Button
              label="Explore Apartments"
              variant={2}
              to="https://www.booking.com/hotel/za/modernday-homes.en-gb.html?aid=898224&app_hotel_id=9662200&checkin=2023-09-28&checkout=2023-10-03&from_sn=ios&group_adults=2&group_children=0&label=hotel_details-tPxpZJ%401694671724&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA%2C"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cta;
