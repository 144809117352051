import React from "react";

const items = [
  {
    id: 1,
    question: "Is there a warranty on furniture items?",
    answer:
      "Yes, All our furniture items come with a 7 days warranty that covers manufacturing defects",
  },
  {
    id: 2,
    question: "What's the average lead time for delivery?",
    answer:
      "Our average lead time for furniture delivery is 3 - 7 business days. For apartments, you can move in within 24 hours of booking!",
  },
  {
    id: 3,
    question: "Do you have a return and refund policy?",
    answer:
      "We offer a hassle-free return and refund policy for our furniture and apartment rentals. Please refer to our terms and conditions for more details",
  },
  {
    id: 4,
    question: "Are there any hidden fees for apartment rentals?",
    answer:
      "Absolutely not! We maintain full transparency, and there are no hidden fees involved in the apartment booking process.",
  },
];
const Faq = () => {
  return (
    <section className="h-full w-full bg-[#ab8f80] py-[120px]">
      <div className="md:w-full w-11/12 container mx-auto">
        <h1 className="md:text-[52px] text-[32px] text-center font-bold text-white/80 font-roman">
          Questions Before You Begin?
        </h1>
        <div className="mt-[40px] w-full md:grid md:grid-cols-2 flex flex-col md:flex-row gap-[60px]">
          {items?.map((item, idx) => {
            return (
              <div key={idx} className="w-full">
                <h2 className="md:text-[32px] text-[20px] font-roman font-semibold text-white/80">
                  {item?.question}
                </h2>
                <p className="text-white/60 font-sans md:text-base text-sm pt-4 md:w-[480px] w-full">
                  {item?.answer}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
