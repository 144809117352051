import React from "react";
import { AiFillHeart } from "react-icons/ai";

const Testimonial = () => {

  const reviews = [
    {
      id: 1,
      name: "Rusell",
      title:
        "Highly recommended!. The apartment was exceptionally clean and well appointed. the facilities are of a high standard and the apartment is very comfortable. We ordered a bottle of wine and a snack platter to the room for our arrival, and the staff were very accommodating of this request and organized to arrange all for",
    },
    {
      id: 2,
      name: "Lethu",
      title:
        "Exceptional. Top notch on en masse, staff incredibly welcoming, friendly and helpful, decor alluring, views from the balcony magnificent, food succullent, the ambiance was quintessential, definitely coming back and distinctly",
    },
  ];


  const Item = ({ review }) => {
     const firstLetter = review?.name && review?.name.charAt(0);
    return (
      <div className="w-full rounded-[16px] bg-white shadow-md border border-[#5A2C1A]/50 p-5">
        <div className="flex items-center gap-4 py-2">
          <div className="h-[50px] w-[50px] rounded-full bg-[#5A2C1A] overflow-hidden flex items-center justify-center text-white font-extrabold text-[20px]">
            {firstLetter}
          </div>
          <div>
            <h3 className=" text-start font-roman font-semibold text-xl text-[#5A2C1A]">
              {review?.name}
            </h3>
            <p className=" text-start font-sans font-medium text-sm text-gray-500 ">
              Customer
            </p>
          </div>
        </div>
        <p className=" text-start font-sans font-medium md:text-base text-sm text-black/70 ">
          {review?.title}
        </p>
      </div>
    );
  };
  return (
    <section className=" py-[80px] w-full bg-testimonial bg-cover bg-no-repeat">
      <div className="md:w-full mx-auto container w-11/12">
        <div className="w-full flex items-center justify-center flex-col">
          <div className="flex items-center gap-3">
            <h3 className="md:pb-2 py-3 text-start font-roman font-bold md:text-[60px] text-[30px] text-[#5A2C1A]">
              Our Wall of
            </h3>
            <AiFillHeart className="md:text-[70px] text-[30px] text-[#ff3e26] ml-3" />
          </div>
          <p className=" text-start font-sans font-medium md:text-base text-sm text-black/70 ">
            Always Propelling results driven growth
          </p>
        </div>
        <div className="w-full md:grid md:grid-cols-3 flex flex-col  gap-[28px] mt-[42px]">
          {reviews.map((review, index) => (
            <Item key={review.id} review={review} />
          ))}

        
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
