import React from "react";
import Navbar from "../components/common/Navbar";
import Hero from "../components/home/Hero";
import Discover from "../components/home/Discover";
import RowCard from "../components/home/RowCard";
import { rowdata } from "../data/rowdata";
import Cta from "../components/home/Cta";
import Faq from "../components/home/Faq";
import Luxury from "../components/home/Luxury";
import Shop from "../components/home/Shop";
import Design from "../components/home/Design";
import Testimonial from "../components/home/Testimonial";
import Footer from "../components/common/Footer";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Discover />
      {rowdata.map((data, index) => (
        <RowCard data={data} key={index} />
      ))}
      <Cta />
      <Luxury />

      {/* <Shop /> */}
      <Design />
      <Testimonial />
      <Faq />
      <Footer />
    </div>
  );
};

export default Home;
