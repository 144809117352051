import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Text from "./text";
import logo from "../../assets/img/logo.png";
import { IoMenuSharp } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  const [open, setOpen] = useState(false);
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

   const [showDropdown, setShowDropdown] = useState(false);

   const toggleDropdown = () => {
     setShowDropdown(!showDropdown);
   };

  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full z-10 transition duration-300 md:py-2 py-1 ${
          scrolled ? "bg-white shadow-b shadow-sm" : "bg-[#E3BC9A]"
        }`}
      >
        <div className="container 2md:w-full mx-auto w-11/12">
          <div className="w-full flex items-center justify-between py-2">
            <div>
              <Link to="/" className="w-full flex items-center">
                <div
                  className={`md:w-24 w-[100px] h-[80px] ${
                    scrolled ? `h-[80px]` : ``
                  }`}
                >
                  <img
                    src={logo}
                    alt="Logo"
                    border="0"
                    className="h-full w-full"
                  />
                </div>
              </Link>
            </div>

            <div className="items-center md:gap-6 gap-4 md:flex hidden">
              <Link to="#" className="">
                <Text
                  as="h1"
                  variant="h1"
                  className="text-sm font-medium text-black/80 font-roman"
                >
                  Furniture Sales
                </Text>
              </Link>
              <Link
                to="https://www.booking.com/hotel/za/modernday-homes.en-gb.html?aid=898224&app_hotel_id=9662200&checkin=2023-09-28&checkout=2023-10-03&from_sn=ios&group_adults=2&group_children=0&label=hotel_details-tPxpZJ%401694671724&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA%2C"
                className=""
              >
                <Text
                  as="h1"
                  variant="h1"
                  className="text-sm font-medium text-black/80 font-roman"
                >
                  Apartment Booking
                </Text>
              </Link>
            </div>
            <div className="md:hidden flex flex-col">
              <button className="nav-toggle" onClick={toggleDropdown}>
                {showDropdown ? (
                  <IoMdClose className="text-[30px]" />
                ) : (
                  <IoMenuSharp className="text-[30px]" />
                )}
              </button>
            </div>
          </div>
          {showDropdown && (
            <div className="mt-5 w-full bg-white rounded-xl p-5 flex flex-col items-center justify-center">
              <div className="flex flex-col gap-5 w-full items-center justify-center font-roman">
                <a href="/">Furniture Sales</a>
                <a href="https://www.booking.com/hotel/za/modernday-homes.en-gb.html?aid=898224&app_hotel_id=9662200&checkin=2023-09-28&checkout=2023-10-03&from_sn=ios&group_adults=2&group_children=0&label=hotel_details-tPxpZJ%401694671724&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA%2C">
                  Apartment Booking
                </a>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
}

export default Navbar;
