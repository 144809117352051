import React from "react";

const RowCard = ({ data }) => {
  return (
    <section className="py-[40px] w-full">
      <div className="md:w-full w-11/12 mx-auto container">
        <div
          className={`w-full flex flex-col-reverse md:flex-row  justify-between items-center gap-8 ${
            0 % 2 === 0 && data.id % 2 !== 0
              ? "flex-col md:flex-row "
              : "md:flex-row-reverse flex-col"
          }`}
        >
          <div
            className={` w-full overflow-hidden md:h-[594px] h-[365px] ${
              0 % 2 === 0 && data.id % 2 !== 0
                ? " rounded-3xl "
                : "rounded-tr-[200px] rounded-br-[200px] rounded-bl-[200px] rounded-tl-[23px]"
            }`}
          >
            <img src={data.img} alt="img" className="w-full h-full  object-cover" />
          </div>
          <div className="w-full max-w-xl">
            <h3 className="md:py-5 py-3 font-roman font-bold md:text-[60px] text-[28px] text-[#5A2C1A] text-center">
              {data.title}
            </h3>
            <p className=" text-center font-sans font-medium md:text-base text-sm text-black/70 ">
              {data.desc}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RowCard;
